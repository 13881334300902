import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/login'
  },
  {
      path: '/home',
      name: 'home',
      component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
      meta: { title: '首页' } 
  },
  {
    path: '/loginDemo',
    name: 'loginDemo',
    // component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    component: () => import(/* webpackChunkName: "about" */ '../views/DemoLogin.vue'),
    meta: { title: '登录' } 
},
{
  path: '/login',
  name: 'login',
  component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
  // component: () => import(/* webpackChunkName: "about" */ '../views/DemoLogin.vue'),
  meta: { title: '登录' } 
},
  {
    path: '/NoData',
    name: 'NoData',
    component: () => import(/* webpackChunkName: "about" */ '../views/NoData.vue'),
    meta: { title: '无权限' } 
},
  {
    path: '/leadershipAssessment',
    name: 'leadershipAssessment',
    component: () => import(/* webpackChunkName: "about" */ '../views/leadershipAssessment/leadershipAssessmentView.vue'),
    meta: { title: '领导力测评' } 
  },
  {
    path: '/leadershipAssessmentDetail',
    name: 'leadershipAssessmentDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/leadershipAssessment/leadershipAssessmentDetail.vue'),
    meta: { title: '领导力测评' } 
  },
  {
    path: '/leadershipAssessmentList',
    name: 'leadershipAssessmentList',
    component: () => import(/* webpackChunkName: "about" */ '../views/leadershipAssessment/leadershipAssessmentList.vue'),
    meta: { title: '领导力测评' } 
  },
  {
    path: '/leadershipAssessmentResult',
    name: 'leadershipAssessmentResult',
    component: () => import(/* webpackChunkName: "about" */ '../views/leadershipAssessment/leadershipAssessmentResult.vue'),
    meta: { title: '领导力测评' } 
  },
  {
    path: '/leadershipAssessmentLook',
    name: 'leadershipAssessmentLook',
    component: () => import(/* webpackChunkName: "about" */ '../views/leadershipAssessment/leadershipAssessmentLook.vue'),
    meta: { title: '答题详情' } 
  },

  {
    path: '/functionAssessmentDetail',
    name: 'functionAssessmentDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/functionAssessment/functionAssessmentDetail.vue'),
    meta: { title: '专业能力测评' } 
  },
  {
    path: '/functionAssessmentError',
    name: 'functionAssessmentError',
    component: () => import(/* webpackChunkName: "about" */ '../views/functionAssessment/functionAssessmentError.vue'),
    meta: { title: '暂无测评' } 
  },
  {
    path: '/functionAssessmentList',
    name: 'functionAssessmentList',
    component: () => import(/* webpackChunkName: "about" */ '../views/functionAssessment/functionAssessmentList.vue'),
    meta: { title: '专业能力测评' } 
  },
  {
    path: '/functionAssessmentResult',
    name: 'functionAssessmentResult',
    component: () => import(/* webpackChunkName: "about" */ '../views/functionAssessment/functionAssessmentResult.vue'),
    meta: { title: '专业能力测评' } 
  },
  {
    path: '/functionAssessmentLook',
    name: 'functionAssessmentLook',
    component: () => import(/* webpackChunkName: "about" */ '../views/functionAssessment/functionAssessmentLook.vue'),
    meta: { title: '答题详情' } 
  },

  {
    path: '/historyView',
    name: 'historyView',
    component: () => import(/* webpackChunkName: "about" */ '../views/history/historyView.vue'),
    meta: { title: '历史测评结果' } 
  },
  {
    path: '/evaluateView',
    name: 'evaluateView',
    component: () => import(/* webpackChunkName: "about" */ '../views/evaluate/evaluateView.vue'),
    meta: { title: '评价下属' } 
  },{
    path: '/invitationAssessmentDetail',
    name: 'invitationAssessmentDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/invitationRe/invitationAssessmentDetail.vue'),
    meta: { title: '领导力复评' } 
  },{
    path: '/invitationAssessmentList',
    name: 'invitationAssessmentList',
    component: () => import(/* webpackChunkName: "about" */ '../views/invitationRe/invitationAssessmentList.vue'),
    meta: { title: '领导力复评' } 
  },{
    path: '/invitationAssessmentDetailTwo',
    name: 'invitationAssessmentDetailTwo',
    component: () => import(/* webpackChunkName: "about" */ '../views/invitationRe/invitationAssessmentDetailTwo.vue'),
    meta: { title: '专业能力复评' } 
  },{
    path: '/invitationAssessmentListTwo',
    name: 'invitationAssessmentListTwo',
    component: () => import(/* webpackChunkName: "about" */ '../views/invitationRe/invitationAssessmentListTwo.vue'),
    meta: { title: '专业能力复评' } 
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})


const editPageTitle = (name)=>{
  document.title = name
  const i = document.createElement('iframe')
  i.src = '/favicon.ico'
  i.style.display = 'none'
  i.onload = function () {
    setTimeout(function () {
      i.remove()
    }, 9)
  }
  document.body.appendChild(i)
}

router.afterEach((route) => {
  editPageTitle(route.matched[0].meta.title || '' );
});
// 路由白名单
const offpath = ['/login','/loginDemo','/NoData','/']

router.beforeEach((to, from, next) => { 
  // AAD登录
  if (to.path.toString().split('=')[0] === '/access_token') {
    const paths = to.path.toString()
    const pathsA = paths.split('=')[1]
    const pathsB = pathsA.split('&')[0]
    sessionStorage.setItem('onetoken',pathsB)
    setTimeout(()=>{
      next('/login')
    },1000)
  }
 else if(offpath.indexOf(to.path) < 0){
    const userData = sessionStorage.getItem('userData') ? JSON.parse(sessionStorage.getItem('userData')) : ''

    const token = userData.accessToken
      if(!token){
        alert('Please login first')
        next('/login')
      }else{
        next()
      }
  }else{
    next()
  }

})

export default router
